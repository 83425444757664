/**
 * Slate Navigation Styles
 * DBS>Interactive
 *
 * ************************************************************************
 * This file is for the navigation menu in the header - both the mobile and
 * desktop menu. Keep header/branding specific styles in _header.scss.
 * ************************************************************************
 *
 * 		menu-toggle - the hamburger menu button
 * 			open - the class given when the toggle button is in the open state
 * 			menu-toggle__bar - the individual hamburger bars
 * 		submenu-toggle - a submenu toggle button
 * 			open - the class given when the toggle button is in the open state
 *
 * 		site-header__navigation - the header container for all site header
 * 			navigation.
 *
 * 			secondary-menu - the (nav element) container for the secondary menu
 * 			main-menu - the (nav element) container for the main menu
 *
 * 		menu - the container class for a menu ( ul element )
 * 			open - the class added to a ( mobile ) menu that is opened
 *
 * 			menu__item - a menu item ( li element )
 * 				menu__item--active - the active item ( current page )
 * 				menu__item--parent - an item that has a submenu
 *
 * 			menu__submenu - a submenu element ( ul element )
 * 				menu__submenu--l1 - a first level submenu
 * 				menu__submenu--l2 - etc...
 *
 */


/**
 * Hamburger Icon
 */
.menu-toggle {
	background-color: transparent;
	border: 0;
	display: block;
	height: 3rem;
	margin-right: -.5em; // Move the right edge of the icon all the way to the edge of the container
	padding: 0.5em;
	width: 3em;

	&__bar {
		background: $blueDarkest;
		border-radius: 3px;
		display: block;
		height: 3px;
		margin: 0 auto 6px;
		opacity: 1;
		transition: transform 0.25s, width 0.25s;
		width: 100%;
		&:last-child { margin-bottom: 0; }
	}

	&.open > .menu-toggle__bar {
		&:nth-child(1) { transform: translate(0, 9px) rotate(45deg); }
		&:nth-child(2) { width: 0; }
		&:nth-child(3) { transform: translate(0, -9px) rotate(-45deg); }
	}

	// Don't display in desktop
	@include media($mobileNavBP) {
		display: none;
	}
}

/**
 * Submenu Toggle Icon
 */
.submenu-toggle {
	background-color: transparent;
	border: 0;
	height: 3.25rem;
	margin: 0;
	padding: 0;
	opacity: 1;
	position: absolute;
		top:  0;
		right: 0;
	transition: transform 0.25s ease-in-out;
	transform: none;
	width: 3rem;
	z-index: 1;

	svg {
		height: 2rem;
		width: .75rem;
		transition: transform .25s ease-in-out;
	}

	&.open {
		svg {
			transform: rotate(180deg);
		}
	}

	&:focus,
	&:active,
	&.open {
		opacity: 1;
	}

	// Desktop styles
	@include media($mobileNavBP) {
		height: 2rem;
		opacity: 0;
		top: 50%;
		transform: translateY(-53%);
		right: -1.25rem;
		width: 2rem;

		svg {height: 100%;}

		.menu__submenu > .menu__item > & {
			opacity: 1;
			right: 0;
			transform: translateY(-50%) rotate(-90deg);
		}
	}
}

.subitem .submenu-toggle {
	@include media($mobileNavBP) {
		position: absolute;
			top: 50%;
	}
}

/**
 * General Menu Styles
 *
 * These styles apply to both the mobile and the desktop menu.
 */
.site-header__navigation > nav {
	font-family: $bodyFont;
	z-index: 100;
	// Resets
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	a {
		color: $blueDarkest;
		display: block;
		font-size: 1em;
		font-weight: 600;
		text-decoration: none;
		transition: background-color .25s ease-in-out, color .25s ease-in-out;

		&:hover, &:active, &:focus {
			color: darken($blueDarkest, 10%);
		}
	}
}

/**
 * Mobile Menu Styles
 */
@include media($mobileNavBPMax, 'max') {

	.site-header__navigation {
		background-color: $white;
		border-bottom: 1px solid $grayLighter;
		max-height: 100vh;
		min-height: 100vh;
		position: absolute;
			top: 100%;
			left: 0;
		transform: translate3d(-100%,0,0);
		transition: transform .25s ease-in-out, visibility 0s .25s linear;
		visibility: hidden;
		width: 90vw;

		/**
		 * Styles for when the mobile menu is opened.
		 */
		 &.open {
			transform: translate3d(0,0,0);
			transition: transform .25s ease-in-out, visibility 0s 0s linear;
			visibility: visible;
		}
	}

	.menu {

		/**
		 * Individual menu item
		 */
		&__item {
			border-top: 1px solid $grayLighter;
			display: block;
			position: relative;

			a {
				padding: 1em 1.5em;
			}
		}

		/**
		 * Submenu
		 */
		&__submenu {
			background-color: $grayLighter;
			max-height: 0;
			overflow: hidden;

			/**
			 * Submenus when hovering parent
			 */
			.open > & {
				max-height: none;
			}

			/**
			 * Submenu item styles
			 */
			.menu__item {
				a {
					background-color: lighten($grayLighter, 10%);
					padding-left: $spacing*2;
					text-transform: none;
					transition: background-color .25s ease-in-out, color .25s ease-in-out;
				}
			}

			/**
			 * Sub-Submenus
			 */
			& & {
				a {
					padding-left: $spacing*3;
				}
			}
		}
	}
}

/**
 * Desktop Menu Styles
 */
@include media($mobileNavBP) {
	.main-menu,
	.secondary-menu {
		display: flex;
			justify-content: flex-end;
	}

	.secondary-menu {margin: 1rem 0;}

	.main-menu {
		font-size: .85rem;

		@include media($screen-lg) {font-size: 1rem;}

		.menu__item.toplevel > {
			a {padding: 1.9rem .5rem;}
		}
	}

	.menu {
		display: flex;
		    align-items: center;
			flex-direction: row;
			justify-content: flex-end;
		position: relative;

		/**
		 * Individual menu item
		 */
		&__item {
			display: block;
			margin: 0 .5em;
			position: relative;

			a {
				padding: .25rem .5rem;
			}
		}

		&__item--parent {
			display: flex;
				align-items: center;

			.submenu-toggle svg {transition: .25s transform;}

			&:hover,
			&:focus {
				&>.submenu-toggle svg {@include media($mobileNavBP) {transform: rotate(180deg);}}
			}
		}

		/**
		 * Submenu
		 */
		&__submenu {
			background-color: $white;
			opacity: 0;
			position: absolute;
			   top: 100%;
			   left: 0;
			transition: opacity .25s ease-in-out,
						visibility .25s ease-in-out;
			visibility: hidden;
			width: 16em;

			/**
			 * Submenus with open class and when hovering parent
			 */
			.open > &,
			.menu__item--parent:hover > & {
				opacity: 1;
				transition: opacity .125s ease-in-out,
							visibility .125s ease-in-out;
				visibility: visible;
			}

			/**
			 * Submenu item styles
			 */
			.menu__item {
				margin: 0;

				a {
					padding: .75em 1.25em;
					text-transform: none;
					width: 100%;

					// Submenu item background color on hover
					&:hover { background-color: darken($white, 5%); }
				}
			}

			/**
			 * Sub-Submenus
			 */
			& & {
				// TODO: Placeholder; remove if not used
				border-left: 1px solid $charcoal;
				left: 100%;
				top: 0;
				transform: none;
			}
		}
	}
}
