/**
 * Header styles
 * DBS>Interactive
 *
 * 		site-header - the page's main header element
 * 			site-header__branding - the company logo
 * 			site-header__navigation - the site navigation
 * 				NOTE: Put navigation styles in _navigation.scss.
 *
 * ************************************************************************
 * This file is for the general header/branding styles. For specific
 * menu/navigation files, use _navigation.scss.
 * ************************************************************************
 */

.site-header {
	background: $white;
	position: relative;
	z-index: 5000;

	h1 { // Offset bottom margin for exact centering
		padding-top: 1rem;
	}

	&__container {
		display: flex;
			align-items: center;
			justify-content: space-between;
		padding: 0 $spacing;
        position: relative;
	}

	/**
	 * Company logo in site header
	 */
	&__branding {
		padding: .5rem 0;

		img {width: 100%;}

		/**
		 * Anchor tag that wraps svg element (logo graphic) for site homepage link
		 */
		a {
			display: block;
			line-height: 0;
			margin-left: -.25em; // Move the left edge of the logo all the way to the edge of the container
			max-width: 10rem;
			padding: .25em;
		}
	}

	@include media ($screen-sm) {
		&__branding {
			a {
				max-width: 12rem;
				padding: .75rem 0 .5rem;
			}
		}
	}

	@include media($screen-lg) {
		&__branding {
			a {
				max-width: 15rem;
			}
		}
	}
}
