/**
 * Theme Variables - colors, font sizes, breakpoint etc.
 * All the variables will goes here based on project tech specification
 * DBS>Interactive
 */
$libraryPath: '';

// Shades of gray
$white: #ffffff;
$whiteDark: #fbfbff;
$black: #000000;
$gray: #dadada;
$grayLighter: #F1F2F2;
$grayDark: #505D68;
$grayDarker: #404144;
$grayDarkest: #2d2d2d;
$charcoal: #3b4646;

// Colors
$blue: #11538B;
$blueDarker: #082C4B;
$blueDarkest: #061B3C;
$orange: #F26525;

// Gradient
$darkLeftLightRight: linear-gradient(to right, $blueDarkest, $blue);
$darkTopLightBottom: linear-gradient(to bottom, $blueDarkest, $blue);


$primaryColor: $blue;
$secondaryColor: $orange;

/**
 * Fonts
 */
$font-bebas-neue: "Bebas Neue", "Verdana", "Helvetica", sans-serif;
$font-montserrat: "Montserrat", "Verdana", "Helvetica", sans-serif;
$bodyFont: $font-montserrat;
$headingFont: $font-bebas-neue;

$textColor: $black;
$bodyFontSize: 1em;
$bodyLineHeight: 1.65;
$ratioScale: 1.25;
$linkColor: $textColor;

// Heading Sizing
$headingColor: $blueDarkest;
$headingFontSize: 1em;
$headingLineHeight: 1.2;

// Layout Vars
$containSizeMoreNarrow: 50rem;
$containSizeNarrow: 65rem;
$containSize: 78rem;
$containSizeWide: 85rem;

$spacing: 1.5rem;
$vSpacing: 1.5rem;

// Media Query Break Points
$screen-xs:  30.000em;  //  480px
$screen-sm:  48.000em;  //  768px
$screen-md:  62.000em;  //  992px
$screen-lg:  75.000em;  // 1200px
$screen-xlg: 100.000em; // 1600px

// Max Breakpoints
$screen-xs-max:  $screen-xs  - .001em;
$screen-sm-max:  $screen-sm  - .001em;
$screen-md-max:  $screen-md  - .001em;
$screen-lg-max:  $screen-lg  - .001em;
$screen-xlg-max: $screen-xlg - .001em;

$mobileNavBP:    $screen-md; // Break mobile nav styles
$mobileNavBPMax: $screen-md-max;

$animationDuration: .75s;
$animationStagger: .25s;

/**
 * Type style definitions
 */
%default-type {
	color: $textColor;
	font-family: $bodyFont;
	letter-spacing: 0.025em;
}

%button-type {
	@extend %default-type;
	font-weight: bold;
}

%header-type {
	color: $headingColor;
    font-family: $headingFont;
	font-weight: 400;
	line-height: $headingLineHeight;
}
