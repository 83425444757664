/**
 * To add a new font:
 * DBS>Interactive
 *
 * Take the fonts and upload them here:
 * https://transfonter.org/
 *
 * Download the resulting files and copy the woff and woff2
 * files into the appropriate directory.
 *
 * Make sure that the font-weight and font-style match the appropriate font files.
 *
 * We generally remove all of the unnecessary glyphs from the font family to make the files as
 * small as possible. If the font is a Google Font, you can copy all of its available glyphs
 * by clicking the "Glyphs" tab on the font's page. Paste those into the "characters" field on 
 * Transfonter and delete all of the characters that are not typically going to be used on an  
 * English language site. When you do this correctly the files downloaded from Transfonter will
 * be prefixed with "subset-". If the site has multiple languages, then don't do that.
 */

/**
 * Headings
 */
@font-face {
	font-family: 'Bebas Neue';
	src: url('#{$libraryPath}/fonts/bebas/subset-BebasNeue-Regular.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/bebas/subset-BebasNeue-Regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

/**
 * Body
 */
@font-face {
	font-family: 'Montserrat';
	src: url('#{$libraryPath}/fonts/montserrat/subset-Montserrat-Light.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/montserrat/subset-Montserrat-Light.woff') format('woff');
	font-display: swap;
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('#{$libraryPath}/fonts/montserrat/subset-Montserrat-Regular.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/montserrat/subset-Montserrat-Regular.woff') format('woff');
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('#{$libraryPath}/fonts/montserrat/subset-Montserrat-Medium.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/montserrat/subset-Montserrat-Medium.woff') format('woff');
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('#{$libraryPath}/fonts/montserrat/subset-Montserrat-SemiBold.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/montserrat/subset-Montserrat-SemiBold.woff') format('woff');
	font-weight: 600;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('#{$libraryPath}/fonts/montserrat/subset-Montserrat-Bold.woff2') format('woff2'),
		 url('#{$libraryPath}/fonts/montserrat/subset-Montserrat-Bold.woff') format('woff');
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

