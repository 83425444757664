/**
 * Default sidebar styles
 * DBS>Interactive
 */


.sidebar {
	.menu {
		display: block;
		list-style: none;
		margin-bottom: 0rem;
		padding-left: 0em;

		ul {
			list-style: none;
			margin-bottom: 0rem;
			padding-left: 0em;
		}

		a {
			display: inline-block;
			padding: .5em;
			text-decoration: none;
			width: 100%;
		}
		.sub-menu {
			padding-left: 1.25em;
		}
	}
}
