/**
 * Header Search Form
 */
.search-form {
	align-self: stretch;
	border-top: 1px solid $grayLighter;
	padding: $spacing;
	z-index: 1000;

	&__form {
		transition: .25s ease-in-out;
		width: 100%;
		z-index: 10;
	}

	&__container {
		display: block;
		float: right;
		position: relative;
		width: 100%;
	}

	&__field {
		background: $white;
		border: none;
		display: block;
		height: 100%;
		width: 100%;
		padding: 1rem 1.5rem;
	}

	&__button {
		background: none;
		border: none;
		position: relative;
			top: -0.5em;
		padding: 0;
		width: 100%;
	}

	&__button--open,
	&__button--search,
	&__button--close {
		background-color: transparent;
		background: url('#{$libraryPath}/icons/search.svg') no-repeat center;
			background-repeat: no-repeat;
			background-position: right;
			background-size: 1.75em;
		border: none;
		display: block;
		height: 100%;
		transition: .5s;
		width: 2.5rem;

		&:focus,
		&:hover {
			background-color: transparent;
			border: 0;
		}
	}

	&__button--search {
		background-position: center;
		position: absolute;
			top: 50%;
			right: 0;
		transform: translateY(-50%);
	}

	.search-form__button--close {
		display: none;
		background-image: url('#{$libraryPath}/icons/close-symbol.svg');
		background-position: .5rem center;
		background-size: 2.5em;
		z-index: 1;
	}

	.search-form__button--open {display: none;}
	
    
	@include media($mobileNavBP) {
        border: none;
		padding: 0;
        
        &.search-form--active {
            .search-form__form {
                overflow: visible;
            }
        }
		
		&__form {
			height: 0;
			overflow: hidden;
			position: absolute;
				top: 100%;
				right: 1rem;
			width: 20em;
		}

		.search-form__button--open {display: block;}
	}
}


//After clicked
.search-form--active {
	.search-form__form {height: 100%;}

	.search-form__button--open {
		display: none;
	}

	.search-form__button--close {
		display: block;
	}

	@include media ($mobileNavBP) {	
		.search-form__container {
			display: block;
			width: 100%;
		}
	}
}

// Search results page
.search-results {
    @include transition-delays('.search-results__card', 20, 0.25s);
        
    .animation-trigger.in-view ~ {
        .search-results__results>.search-results__card {
            opacity: 1;
            transform: trans1late3d(0,0,0);
        }
	}
    
    &__card {
        border-radius: 1rem;
        box-shadow: 0px 2px 25.8px 0px rgba(0, 0, 0, 0.10);
        opacity: 0;
        overflow: hidden;
		position: relative;
        transform: translate3d(0, 2rem, 0);
        transition: opacity $animationDuration ease-in-out, transform $animationDuration ease-in-out;

        &-body {
            display: flex;
                flex-direction: column;
            height: calc(100% - 12rem);
            padding: $spacing;
            
            &:active,
            &:focus,
            &:hover {
                background-color: $whiteDark;
            }
        }
    
        &-header {
            background: linear-gradient(12deg, rgba(52, 100, 146, 0.34) 8.6%, rgba(9, 20, 59, 0.34) 81.82%), #33628F;
/*             border-start-start-radius: 1rem;
            border-start-end-radius: 1rem; */
            display: flex;
                align-items: center;
                justify-content: center;
            height: 12rem;
            position: relative;
            text-align: center;

            h4 {
                color: $blueDarkest;
                margin-bottom: 0;
                opacity: 0.16;
            }
        }
    }
    
    &__excerpt {
        font-size: 0.875rem;
        margin-bottom: $spacing;
    }
    
    &__image {
        img {
            @include object-fit(cover, center);
/*             border-start-start-radius: 1rem;
            border-start-end-radius: 1rem; */
            height: auto;
        }
    }

    &__link {
        margin-top: auto;
    }

    &__title {
        h3 {
            color: $blue;
        }
    }

    &.full-width {
        .contain>* {
            opacity: 1;
        }
    }
    
    @include media($screen-sm) {
        &__card {
            width: calc(33.33% - #{$spacing});
        }

        &__results {
            display: flex;
                flex-wrap: wrap;
                gap: #{$spacing * 3 / 2};
                justify-content: center;
        }
    }

    @include media($screen-sm-max, 'max') {

        &__card:not(:last-child) {
            margin-bottom: $spacing;
        }
    }
}