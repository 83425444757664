/**
 * DBS>Interactive
 * General Theme Type classes
 * These classes are generic repeatable patterns of type styles that can be
 * extended by other classes to maintain consistency throughout the theme.
 *
 * Recommended usage:
 * 		@extend %button-type;
 */


/**
 * Default Typography
 */
html {
	@extend %default-type;
	font-size: $bodyFontSize;
}

body {
	@extend %default-type;
	font-size: $bodyFontSize;
	font-weight: 300;
	line-height: $bodyLineHeight;
}


nav {
	@extend %header-type;
}


h1, h2, h3, h4, h5, h6 {
	@extend %header-type;
	margin-top: 0;
	margin-bottom: $vSpacing * 1;
	text-rendering: optimizeLegibility;

	a { text-decoration: none; }
}


.h1, h1 { @include font-scale( 5, $headingFontSize); }
.h2, h2 { @include font-scale( 4, $headingFontSize); }
.h3, h3 { @include font-scale( 3, $headingFontSize); }
.h4, h4 { @include font-scale( 2, $headingFontSize); }
.h5, h5 { @include font-scale( 1, $headingFontSize); }
.h6, h6 { @include font-scale( 1, $headingFontSize); }

@include media($screen-md) {
	.h1, h1 { @include font-scale( 6, $headingFontSize); }
	.h2, h2 { @include font-scale( 5, $headingFontSize); }
	.h3, h3 { @include font-scale( 4, $headingFontSize); }
	.h4, h4 { @include font-scale( 3, $headingFontSize); }
	.h5, h5 { @include font-scale( 2, $headingFontSize); }
	.h6, h6 { @include font-scale( 1, $headingFontSize); }
}

.eyebrow { 
    color: $grayDark;
    font-family: $font-montserrat;
	font-size: 1.125rem;
    font-style: normal;
	font-weight: 500;
    line-height: 2;
	margin-bottom: $spacing;
    padding-bottom: $spacing;
    position: relative;
    text-transform: uppercase;
    
    &::after {
        background-color: $orange;
        bottom: 0;
        content: '';
        height: .25rem;
        left: 0;
        position: absolute;
        width: 4.25rem;
    }
    
    &.center {
        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }
}

.bg-dark .eyebrow {color: $white}

.text-center .eyebrow::after,
.text-center.eyebrow::after {
	left: 50%;
	transform: translateX(-50%);
}

a {
	color: $linkColor;
	font-weight: 500;
	text-decoration: underline;
	transition: color ease-in-out 0.25s;
	&:hover, &:focus, &:active { color: darken($linkColor, 10%); text-decoration: none;}
}

.bg-dark a {
	color: $white;
	&:hover, &:focus, &:active { color: darken($white, 10%); }
}


p {
	margin-top: 0;
	margin-bottom: $spacing;
}

.dropcap {
	color: $primaryColor;
	float: left;
	font-size: 6.25em;
	font-weight: 400;
	line-height: 90px;
	margin-bottom: -.031em;
	margin-top: .03em;
	padding-right: .5rem;
}

strong {
    font-weight: 700;
}

.font_small,
small { font-size: 0.707em; }

.intro-text,
.bigger-text {font-size: 1.125rem;}

// Color Classes
.white {color: $white !important;}
.black {color: $black !important;}
.blue {color: $blue !important;}

// Type Style Classes
.caps {text-transform: uppercase;}
.underline {text-decoration: underline;}

// Alignment Classes
.text-center {text-align: center;}
.text-left {text-align: left;}
.text-right {text-align: right;}

// Font Weight Classes
.normal {font-weight: 400;}
.semi-bold {font-weight: 600;}
.bold {font-weight: 700;}
.extra-bold {font-weight: 900;}

// Font Families
.montserrat {font-family: $font-montserrat;}
.bebas-neue {font-family: $font-bebas-neue;}
