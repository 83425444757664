/**
 * Theme Buttons
 * DBS>Interactive
 */

/**
 * 	Button color variable
 *	$grayDarkest: #2d2d2d; —— See _vars.scss
 */
 $buttonColor: $blue;


/**
 * Button mixin
 * @param $color1 - foreground color
 * @param $color2 - background color
 */
@mixin button ($color1, $color2) {
	@extend %button-type;
	background-color: $color2;
	border: 1px solid $color2;
	border-radius: 2rem;
	color: $color1;
	display: inline-block;
	font-weight: 700;
	padding: 0.75em 2.5em;
	text-decoration: none;
	transition: all .25s ease;
	-webkit-appearance: nowrap;
	white-space: nowrap;

	// Swap the colors on hover
	&:hover, &:active, &:focus {
		background-color: $color1;
		border-color: $color2;
		color: $color2;
	}
}


/**
 *
 *	CSS Classes (or how to apply the mixin declared above)
 *   	Overview:
 *  		There is a .button class
 *  		There is a modifying .button--ghost class
 *  			.button--ghost builds on .button by being included after the button class and applying a few overrides to the .button styling.
 *
 *  			HTML Example:
 *  			<a class="button button--ghost" href="#">Example Button</a>
 *
 *  	Additional styles can be added on a per project basis. When possible, use the .button--ghost class as an example
 *  	and build off of the .button class styles; avoid creating a separate class and using @include button(), for this
 *  	will cause (potential) unnecessary CSS bloat.
 */

/**
 *  Button Styling
 */
.button {
	@include button ($white, $buttonColor);

	&.arrow {
		padding-right: 3.5rem;
		position: relative;

		&:before,
		&:after {
			content: '';
			height: 1.25rem;
			width: 1.25rem;
			position: absolute;
				top: 50%;
				right: 1.25rem;
			transform: translate(0, -50%);
			transition: .25s ease-in-out;
		}

		&:before {
			background-image: url('#{$libraryPath}/icons/arrow-right-white.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			opacity: 1;
		}

		&:after {
			background-image: url('#{$libraryPath}/icons/arrow-right.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
			opacity: 0;
		}

		&:hover,
		&:focus {
			&:before,
			&:after { transform: translate(.5rem, -50%); }

			&:before {opacity: 0;}
			&:after {opacity: 1;}
		}
	}

	/**
	 *  Ghost Button Styling
	 */
	&.ghost {
		background-color: transparent;
		color: $buttonColor;

		&:hover, &:active, &:focus {
			background-color: $buttonColor;
			color: $white;
		}
	}

	/**
	 * Orange Button
	 */
	&.orange {
		@include button ($white, $orange);
	}
    
    &.blue {
        @include button( $white, $blue );
    }
    

}

/**
 *	Button styling on a dark background
 */
.bg-dark .button {
	@include button ($buttonColor, $white);

	&.ghost {
		background-color: transparent;
		color: $white;
		&:hover, &:active, &:focus {
			background-color: $white;
			color: $black;
		}
	}

	&.arrow {
		padding-right: 3.5rem;

		&:after {
			background-image: url('#{$libraryPath}/icons/arrow-right.svg');
				background-position: center;
				background-repeat: no-repeat;
				background-size: contain;
		}

		&:before {opacity: 0;}
		&:after {opacity: 1;}

		&:hover,
		&:focus {
			&:before {opacity: 1;}
			&:after {opacity: 0;}
		}
	}

	&.orange {
		@include button ($white, $orange);
	}
    
    &.blue {
        @include button( $white, $blue );
        color: $white !important;
        
        &:active,
        &:focus,
        &:hover {
            color: $blue !important;
        }
        
        &.subscribe {
            border-radius: 0;
            border-start-end-radius: .5rem;
            border-end-end-radius: .5rem;
            font-weight: 400;
            padding: .75rem 2rem;
        }
    }
    
    @media (min-width: 48rem) and (max-width: 74.999rem) {
        &.blue.subscribe {
            padding: .75rem;
        }
    }
    
    @media (min-width: 48rem) and (max-width: 74.999rem) {
        &.blue.subscribe {
            padding: .75rem 1.5rem;
        }
    }
    
    @include media($screen-xs-max, 'max') {
        &.blue.subscribe {
            padding: .75rem;
        }
    }
}

button {
    &.non-button {
        background-color: transparent;
        border: 0;
    }
}

/**
 *	Inline (Arrow) Button Styling
 */
.arrow-link {
	color: $blue;
	padding-right: 2rem;
	position: relative;
	text-decoration: none;
    text-transform: uppercase;

	&:after {
		background-image: url('#{$libraryPath}/icons/arrow-right.svg');
			background-position: center;
			background-repeat: no-repeat;
			background-size: contain;
		content: '';
		display: inline-block;
		height: 1.5rem;
		position: absolute;
			top: 50%;
			right: -0;
		transform: translate(0,-50%);
		transition: transform .25s ease-in-out;
		width: 1.5rem;
	}
	
	&:hover, &:focus {
		color: $textColor;

		&:after {transform: translate(.5em, -50%);}
	}

	&.back {
		padding-left: 2rem;

		&:after {
			left: 0;
			right: auto;
			transform: translate(0,-50%) rotate(180deg);
		}

		&:hover,
		&:focus {
			&:after {transform: translate(-.5rem,-50%) rotate(180deg);}
		}
	}
    
    &.circled {
        font-weight: 700;
        text-transform: none;
        
        &:after {
            background-image: url('#{$libraryPath}/icons/arrow-right-circled.svg');
        }
    }

    &.circled-alt {
        font-weight: 700;
        text-transform: none;
        
        &:after {
            background-image: url('#{$libraryPath}/icons/arrow-right-circled-2.svg');
        }
    }
}

.bg-dark .arrow-link {
	color: $white;
	&:hover, &:focus {
		color: $white;
	}
	&:after {
		background-image: url('#{$libraryPath}/icons/arrow-right-white.svg');
	}
}
